<template>
  <div class="message">
    <div class="pointscashback">
      <div class="card-header">
        <i class="el-icon-edit"></i>
        <span>Message</span>
      </div>
      <div class="card-body">
        <el-table :data="tableData"
                  style="width: 100%"
                  border
                  @cell-mouse-enter="mouseMessage">
          <el-table-column label="Message"
                           prop="message">
                           <template slot-scope="scope">
                             <p v-show='scope.row.status === 0' style="width: 60px;color: white;background:#f86c6b;border-radius: 10px;text-align: center">Unread</p>
                             {{ scope.row.message }}</template>
                           </el-table-column>
          <el-table-column prop="froms"
                           label="From Type"
                           width="150"></el-table-column>
          <el-table-column prop="notification_time"
                           label="CreateDate"
                           width="250">
                          <template slot-scope="scope">
                            {{scope.row.notification_time | formatDate}}
                          </template>
          </el-table-column>
        </el-table>
        <!--引入分页器 -->
        <div class="block">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[10, 20, 50, 100]"
                         :page-size="curreneSize"
                         layout="total,sizes, prev, pager, next, jumper"
                         :total="currentTotal">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  getUesrmessage, // 阅读通知
  usermessage // 用户查询自己的通知
} from '../../api/buyerAfter'
// 日期格式化
import moment from 'moment'
export default {
  data () {
    return {
      // 分页
      currentPage: 1,
      curreneSize: 10,
      currentTotal: 0,
      tableData: []
    }
  },
  created () {
    this.$store.commit('getCount')
    this.mesaageTest(this.currentPage, this.curreneSize)
  },
  methods: {
    // 通知列表
    mesaageTest (currentPage, curreneSize) {
      this.curreneSize = curreneSize
      // 用户查询自己的通知
      usermessage({
        userId: localStorage.getItem('buyer-userId'),
        page: currentPage,
        size: curreneSize
      }).then(result => {
        if (result.data.code === 200) {
          // console.log(result)
          this.tableData = result.data.data.rows
          this.currentTotal = result.data.data.total
          this.tableData.map(v => {
            // v.notification_time = moment(v.notification_time).format('YYYY-MM-DD hh:mm')
            if (v.froms === '系统') {
              v.froms = 'sys'
            } else if (v.froms === '买方') {
              v.froms = 'buyer'
            } else if (v.froms === '卖方') {
              v.froms = 'seller'
            }
          })
        }
      }).catch(err => {
        return err
      })
    },
    // 阅读通知
    mouseMessage (row) {
      if (row.status === 0) {
        // 阅读通知
        getUesrmessage({
          id: row.id
        }).then(result => {
          if (result.data.code === 200) {
            this.mesaageTest(this.currentPage, this.curreneSize)
            this.$store.commit('getCount')
          }
        }).catch(err => {
          return err
        })
      }
    },
    handleSizeChange (val) {
      // (`每页 ${val} 条`)
      this.mesaageTest(this.currentPage, val)
      // console.log(val)
    },
    handleCurrentChange (val) {
      // (`当前页: ${val}`)
      // console.log(val, this.curreneSize)
      this.mesaageTest(val, this.curreneSize)
    }
  }
}
</script>

<style lang='less' scoped>
.message {
  .el-pagination {
    float: left !important;
    margin-top: 20px;
  }
  .card-header {
    padding: 0 20px;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #c8ced3;
    span {
      margin-left: 10px;
      line-height: 45px;
    }
  }
  .el-col-6 {
    margin-bottom: 30px;
  }
  .card-body {
    padding: 20px;
    flex: 1 1 auto;
    background-color: #fff;
    .request {
      color: #fff;
      background-color: #20a8d8;
      border-color: #20a8d8;
      &:hover {
        color: #fff;
        background-color: #1b8eb7;
        border-color: #1985ac;
      }
    }
    .block {
      overflow: hidden;
      /deep/ .el-pagination {
        padding: 0;
        margin-top: 20px;
      }
      /deep/.el-pagination__total {
        border: 1px solid #dcdfe6;
        margin: 0;
      }
      /deep/ .btn-prev,
      /deep/ .btn-next {
        border: 1px solid #dcdfe6;
      }
      /deep/ .number {
        border: 1px solid #dcdfe6;
      }
    }
  }
}
</style>
